export const constactoLang = {
  es: {
    informacionDeContacto: "Informacion de contacto",
    llamanos:'Llamanos:',
    ubicacion:'Ubicación:',
    horariosDeTrabajo:'Horarios de trabajo:',
    lunesAViernes:'Lunes a viernes',
    formularioDeContacto:'Formulario de contacto',
    nombre:'Nombre:',
    correoElectronico: 'Correo electrónico',
    asunto:'Asunto:',
    descripcion:'Descripción:',
    enviar: 'Enviar',
    ingresaTuNombre:'Ingresá tu nombre',
    ingresaTuCorreo:'Ingresá tu correo electrónico',
    asunto2:'Asunto',
    descripcion2:'Descripción',
  },

  en: {
    informacionDeContacto: "Contact information",
    llamanos:'Call us:',
    ubicacion:'Location:',
    horariosDeTrabajo:'Work hours:',
    lunesAViernes:'From monday to friday',
    formularioDeContacto:'Contact Form',
    nombre:'Name:',
    correoElectronico: 'Email',
    asunto:'Topic',
    descripcion:'Description:',
    enviar: 'Send',
    ingresaTuNombre:'Enter your name',
    ingresaTuCorreo:'Enter your email',
    asunto2:'Topic',
    descripcion2:'Description',
  },
};
