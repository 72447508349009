export const searchBarLang = {
  es: {
    buscar: "Buscar",
    input: "Escriba aqui el celular que desea buscar...",
  },

  en: {
    buscar: "Find",
    input: "Write here the cell phone you want to search...",
  },
};
