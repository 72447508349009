export const paginateLang = {
  es: {
    anterior: "Anterior",
    siguiente: "Siguiente",
  },

  en: {
    anterior: "Previous",
    siguiente: "Next",
  },
};
