export const navBarLang = {
    es: {
      ingresa: 'Ingresá',
      creaTuCuenta: 'Crea tu cuenta',
      contacto:'Contacto',
    },
  
    en: {
        ingresa: 'Login',
        creaTuCuenta: 'Sign in',
        contacto:'Contact',
    },
  };
  