export const cardLang = {
  es: {
    'AgregarAlCarrito':'Agregar al carrito',
    'AGOTADO':'AGOTADO',
    'Detalle':'Detalle',
    'Disponibles':'Disponibles',
    'NoDisponible':'No Disponible',
    
  },

  en: {
    'AgregarAlCarrito':'Add to cart',
    'AGOTADO':'SOLD OUT',
    'Detalle':'Details',
    'Disponibles':'Available',
    'NoDisponible':'Not Avaible',
  },
};


