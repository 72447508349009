import React from 'react'
import NavBar from "../NavBar/NavBar"

const LandingPage = () => {
  return (
    <div>
      <NavBar/>
    </div>
  )
}

export default LandingPage