export const backLang = {
  es: {
    volver: "Volver",
    login: "Ingresa",
  },

  en: {
    volver: "Go back",
    login: "Login",
  },
};
