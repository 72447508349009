export const aboutLang = {
    es: {
      volver: "Volver",
      conocenos: "Conocenos",
      int: "Integrantes del proyecto",
    },
  
    en: {
      volver: "Back",
      conocenos: "Know us",
      int: "Project members"
    },
  };